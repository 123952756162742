<template>
  <ModelManagerStyled class="model-manager">
    <div class="title">
      {{ title | capitalize }}
      <CountCircle class="count" :count="totalCount" />
    </div>
    <div class="search">
      <input type="text" v-model="search" @keydown="searchDebounced" />
    </div>
    <div class="headers">
      <slot name="headers" />
      <div class="actions"></div>
    </div>
    <div class="main">
      <ModelStyled v-for="model in models" :key="model.id" class="model-styled">
        <slot :name="model.id" />
        <button v-if="hasDeletePermission" @click="confirmDelete(model)">
          <TrashIcon />
        </button>
        <button v-else disabled>
          <TrashIcon />
        </button>
        <router-link v-if="editRouteName" :to="{ name: editRouteName, params: { id: model.id } }">
          <Edit2Icon />
        </router-link>
        <button v-else disabled>
          <Edit2Icon />
        </button>
        <button v-if="duplicateRouteName" @click="duplicate(model.id)">
          <CopyIcon />
        </button>
        <button v-else disabled>
          <CopyIcon />
        </button>
      </ModelStyled>
      <transition name="fade">
        <LoadingOverlayStyled v-if="loading">
          <TalpaLoaderWrapper />
        </LoadingOverlayStyled>
      </transition>
    </div>
    <div class="footer">
      <button :disabled="!hasPreviousPage" @click="$emit('prev-page')">
        <ChevronLeftIcon />
      </button>
      <button :disabled="!hasNextPage" @click="$emit('next-page')">
        <ChevronRightIcon />
      </button>
      <AlertTriangleIcon v-if="hasErrors" />
      <button v-if="createRouteName" @click="create">
        <PlusIcon />
      </button>
      <button @click="$emit('reload')">
        <RefreshCcwIcon />
      </button>
    </div>
  </ModelManagerStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import debounce from 'lodash/debounce'
import gql from 'graphql-tag'
import get from 'lodash/get'
import {
  AlertTriangleIcon,
  TrashIcon,
  Edit2Icon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusIcon,
  RefreshCcwIcon,
  CopyIcon,
} from 'vue-feather-icons'

import { flexCenter, buttonReset } from '@styles/mixins'

import { CountCircle, TalpaLoaderWrapper } from '@common/components'
import { FlashMessages } from '@common/singletons'
import { getRolesFromToken } from '@common/utils'

const ModelManagerStyled = styled('div')`
  display: grid;
  margin: 2rem;
  background: ${p => chroma(p.theme.colors.white).alpha(0.1).css()};
  backdrop-filter: blur(7px);
  border-radius: 0.5rem;
  grid-template-areas:
    'title search'
    'headers headers'
    'main main'
    'footer footer';

  > .title {
    ${flexCenter}
    justify-content: flex-start;
    grid-area: title;
    padding: 0.5rem;
    border-bottom: 2px solid ${p => chroma(p.theme.colors.black).alpha(0.9).css()};
    .count {
      margin-left: 0.5rem;
    }
  }

  > .search {
    ${flexCenter}
    justify-content: flex-end;
    grid-area: search;
    padding: 0.5rem;
    border-bottom: 2px solid ${p => chroma(p.theme.colors.black).alpha(0.9).css()};
    input {
      color: ${p => p.theme.colors.archonBlue};
      padding: 0.5rem;
      background: ${p => chroma(p.theme.colors.black).alpha(0.3).css()};
      border: none;
      outline: none;
    }
  }

  > .headers {
    grid-area: headers;
    display: grid;
    grid-template-columns: auto 2.5rem 2.5rem 2.5rem;
    grid-template-rows: 2.5rem;
  }

  > .main {
    grid-area: main;
    position: relative;
    min-height: calc(10 * 41px);
    background: ${p => chroma(p.theme.colors.black).alpha(0.4).css()};
  }

  > .footer {
    grid-area: footer;
    ${flexCenter}
    justify-content: flex-start;
    min-height: 2rem;
    border-top: 2px solid ${p => chroma(p.theme.colors.black).alpha(0.9).css()};
    button {
      ${buttonReset}
      ${flexCenter}
    }
  }

  .cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const ModelStyled = styled('div')`
  display: grid;
  grid-template-columns: auto 2.5rem 2.5rem 2.5rem;
  grid-template-rows: minmax(2.5rem, auto);
  background: ${p => chroma(p.theme.colors.black).alpha(0.2).css()};
  border-bottom: 1px solid ${p => chroma(p.theme.colors.black).alpha(0.9).css()};

  &:hover {
    background: ${p => chroma(p.theme.colors.white).alpha(0.1).css()};
  }

  .email {
    padding: 0.5rem;
  }

  button,
  a {
    ${buttonReset}
    ${flexCenter}
  }
`

const LoadingOverlayStyled = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: ${p => chroma(p.theme.colors.black).alpha(0.8).css()};
`

export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    hasPreviousPage: {
      type: Boolean,
      required: true,
    },
    hasNextPage: {
      type: Boolean,
      required: true,
    },
    searchQuery: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    totalCount: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    models: {
      type: Array,
      required: true,
    },
    modelName: {
      type: String,
      required: true,
    },
    errors: {
      type: Array,
      required: true,
    },
    editRouteName: {
      type: String,
      default: '',
    },
    duplicateRouteName: {
      type: String,
    },
    createRouteName: {
      type: String,
    },
    labelKey: {
      type: String,
      default: 'name',
    },
    hasDeletePermission: {
      type: Boolean,
      default: false,
    },
    customDeleteModel: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ModelManagerStyled,
    ModelStyled,
    AlertTriangleIcon,
    CountCircle,
    TrashIcon,
    Edit2Icon,
    PlusIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    RefreshCcwIcon,
    LoadingOverlayStyled,
    TalpaLoaderWrapper,
    CopyIcon,
  },
  data() {
    return {
      search: '',
    }
  },
  watch: {
    searchQuery: {
      handler(s) {
        this.search = s
      },
      immediate: true,
    },
  },
  created() {
    this.searchDebounced = debounce(
      e => {
        const s = e.srcElement.value
        this.$emit('update:searchQuery', s)
      },
      100,
      { trailing: true },
    )
  },
  computed: {
    hasErrors() {
      return this.errors.length > 0
    },
    modelNameCapitalized() {
      return this.modelName[0].toUpperCase() + this.modelName.slice(1)
    },
    userRoles() {
      return getRolesFromToken(this.$keycloak.token)
    },
    isArchon() {
      return this.userRoles.includes('archon')
    },
  },
  methods: {
    create() {
      this.$router.push({ name: this.createRouteName })
    },
    duplicate(id) {
      this.$router.push({
        name: this.duplicateRouteName,
        params: {
          id,
        },
      })
    },
    confirmDelete(model) {
      if (model && model.__typename === 'User') {
        if (get(model, 'roles', []).includes('archon') && !this.isArchon) {
          FlashMessages.$emit('error', new Error(`Only an Archon can delete a Archon`))
          return
        }
      }
      this.$root.$emit(
        'activateOverlay',
        'ConfirmDeleteOverlay',
        {
          type: this.modelNameCapitalized,
          instance: model,
          labelKey: this.labelKey,
          onConfirm: this.delete,
          onConfirmArgs: model,
        },
        this.onCloseSettings,
      )
    },
    async delete(model) {
      if (this.customDeleteModel) {
        this.$emit('deleteModel', model)
        return
      }
      if (model && model.__typename === 'User') {
        const res = await this.$apollo.mutate({
          mutation: gql`
            mutation ($id: String!) {
              delete${this.modelNameCapitalized}(where: {id: $id})
            }
          `,
          variables: {
            id: model.id,
          },
        })
        if (res) {
          this.$root.$emit('closeOverlay')
          this.$emit('reload')
        }
        this.$emit('reload')
        return true
      }
      const res = await this.$apollo.mutate({
        mutation: gql`
          mutation ($id: ID!) {
            delete${this.modelNameCapitalized}(where: {id: $id}) {
              id
            }
          }
        `,
        variables: {
          id: model.id,
        },
      })
      if (res) {
        this.$root.$emit('closeOverlay')
        this.$emit('reload-models')
      }
    },
  },
}
</script>
