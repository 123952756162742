var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ModelManagerStyled', {
    staticClass: "model-manager"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm._f("capitalize")(_vm.title)) + " "), _c('CountCircle', {
    staticClass: "count",
    attrs: {
      "count": _vm.totalCount
    }
  })], 1), _c('div', {
    staticClass: "search"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search,
      expression: "search"
    }],
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.search
    },
    on: {
      "keydown": _vm.searchDebounced,
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.search = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "headers"
  }, [_vm._t("headers"), _c('div', {
    staticClass: "actions"
  })], 2), _c('div', {
    staticClass: "main"
  }, [_vm._l(_vm.models, function (model) {
    return _c('ModelStyled', {
      key: model.id,
      staticClass: "model-styled"
    }, [_vm._t(model.id), _vm.hasDeletePermission ? _c('button', {
      on: {
        "click": function click($event) {
          return _vm.confirmDelete(model);
        }
      }
    }, [_c('TrashIcon')], 1) : _c('button', {
      attrs: {
        "disabled": ""
      }
    }, [_c('TrashIcon')], 1), _vm.editRouteName ? _c('router-link', {
      attrs: {
        "to": {
          name: _vm.editRouteName,
          params: {
            id: model.id
          }
        }
      }
    }, [_c('Edit2Icon')], 1) : _c('button', {
      attrs: {
        "disabled": ""
      }
    }, [_c('Edit2Icon')], 1), _vm.duplicateRouteName ? _c('button', {
      on: {
        "click": function click($event) {
          return _vm.duplicate(model.id);
        }
      }
    }, [_c('CopyIcon')], 1) : _c('button', {
      attrs: {
        "disabled": ""
      }
    }, [_c('CopyIcon')], 1)], 2);
  }), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.loading ? _c('LoadingOverlayStyled', [_c('TalpaLoaderWrapper')], 1) : _vm._e()], 1)], 2), _c('div', {
    staticClass: "footer"
  }, [_c('button', {
    attrs: {
      "disabled": !_vm.hasPreviousPage
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('prev-page');
      }
    }
  }, [_c('ChevronLeftIcon')], 1), _c('button', {
    attrs: {
      "disabled": !_vm.hasNextPage
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('next-page');
      }
    }
  }, [_c('ChevronRightIcon')], 1), _vm.hasErrors ? _c('AlertTriangleIcon') : _vm._e(), _vm.createRouteName ? _c('button', {
    on: {
      "click": _vm.create
    }
  }, [_c('PlusIcon')], 1) : _vm._e(), _c('button', {
    on: {
      "click": function click($event) {
        return _vm.$emit('reload');
      }
    }
  }, [_c('RefreshCcwIcon')], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }